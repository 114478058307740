import { FixedShape } from "@iventis/domain-model/model/fixedShape";
import { feature, featureCollection, point } from "@turf/helpers";
import rhumbBearing from "@turf/rhumb-bearing";
import GeoJSON from "geojson";
import { midpointHandleLayerId } from "../bridge/constants";
import { FixedShapeSupportedGeometry, Listener, MoveEvent } from "../types/internal";
import { MapObjectProperties } from "../types/store-schema";
import { getCoordinateList, getMidpointsRhumb, resizeRectangleOneDirectionWithMidpoints, getIndexClosestCoordinate, ensure360Bearing } from "./geojson-helpers";
import { Midpoint } from "./midpoint-handles";

export enum FixedShapeMidpointHandleEvent {
    START_DRAG = "START_DRAG",
    DRAG = "DRAG",
    RELEASE = "RELEASE",
    ENTER = "ENTER",
    LEAVE = "LEAVE",
}

interface ListenerPattern {
    [FixedShapeMidpointHandleEvent.DRAG]: ((transformation: GeoJSON.Feature, newCoordinate: { lng: number; lat: number }, indexOfMidpoint?: number) => void)[];
    [FixedShapeMidpointHandleEvent.START_DRAG]: ((point: Midpoint, cursorBearing: number) => void)[];
    [FixedShapeMidpointHandleEvent.RELEASE]: ((payload: { transformation: GeoJSON.Feature; coordinate: GeoJSON.Position }) => void)[];
    [FixedShapeMidpointHandleEvent.ENTER]: ((cursorBearing: number) => void)[];
    [FixedShapeMidpointHandleEvent.LEAVE]: (() => void)[];
}

export interface Dragging {
    isDragging: boolean;
}

export class FixedShapeMidpointHandles {
    private listeners: ListenerPattern = {
        [FixedShapeMidpointHandleEvent.ENTER]: [],
        [FixedShapeMidpointHandleEvent.RELEASE]: [],
        [FixedShapeMidpointHandleEvent.LEAVE]: [],
        [FixedShapeMidpointHandleEvent.START_DRAG]: [],
        [FixedShapeMidpointHandleEvent.DRAG]: [],
    };

    private midpointCollection: GeoJSON.FeatureCollection<GeoJSON.Point>;

    private mouseDownHandleListener: Listener;

    private enteredHandle: boolean;

    private mouseMoveListener: Listener;

    private mouseUpListener: Listener;

    private mouseDragListener: Listener;

    private onContentUnderMouseChangedListener: Listener;

    private anchorMidpoint: GeoJSON.Position;

    constructor(
        private feature: GeoJSON.Feature<FixedShapeSupportedGeometry, MapObjectProperties>,
        public operations: {
            onMouseMove: (callback: (e: MoveEvent) => void, radius?: number) => Listener;
            onMouseDownHandle: (callback: (point: Midpoint) => void) => Listener;
            onMouseUp: (callback: (e: { lng: number; lat: number }) => void) => Listener;
            onContentUnderMouseChanged: (callback: (e: MoveEvent) => void) => Listener;
            setHandleGeometry: (midpointCollection: GeoJSON.FeatureCollection<GeoJSON.Point>) => void;
        }
    ) {
        let dragStarted = false;

        this.midpointCollection = getCoordinateGeometry(getCoordinateList(feature.geometry), feature.properties.fixedShape);
        this.operations.setHandleGeometry(this.midpointCollection);

        const contentUnderMouseChanged = (event: MoveEvent) => {
            if (dragStarted) {
                return;
            }
            const midpointHandleObject = event.objects.find((object) => object.properties.layerid === midpointHandleLayerId);

            if (midpointHandleObject && !this.enteredHandle) {
                this.enteredHandle = true;

                const cursorBearing = this.getCursorBearing(midpointHandleObject as GeoJSON.Feature<GeoJSON.Point>);

                this.listeners.ENTER.forEach((listener) => listener(cursorBearing));
            } else if (
                // We check here if it is undefined first, so we can set it to false and send a leave event to ensure transparency
                (this.enteredHandle === true || this.enteredHandle === undefined) &&
                !event.objects.some((object) => object.properties.layerid === midpointHandleLayerId)
            ) {
                this.enteredHandle = false;
                this.listeners.LEAVE.forEach((listener) => listener());
            }
        };

        const dragFunction = (startingPoint: Midpoint) => {
            dragStarted = false;

            this.mouseUpListener = this.operations.onMouseUp((position) => {
                this.mouseUpListener.remove();
                this.mouseUpListener = undefined;
                this.mouseDragListener.remove();
                const transformation = dragStarted ? this.getTransformation(startingPoint.geometry.coordinates, position) : this.feature;
                this.listeners[FixedShapeMidpointHandleEvent.RELEASE].forEach((listener) => listener({ transformation, coordinate: [position.lng, position.lat] }));
                this.anchorMidpoint = undefined;
            });

            this.mouseDragListener = this.operations.onMouseMove((position) => {
                if (!dragStarted) {
                    this.removeHandles();
                    dragStarted = true;

                    const cursorBearing = this.getCursorBearing(point([position.lng, position.lat]));

                    this.listeners[FixedShapeMidpointHandleEvent.START_DRAG].forEach((listener) => listener(startingPoint, cursorBearing));
                }

                const transformation = this.getTransformation(startingPoint.geometry.coordinates, position);

                const indexOfMovingMidpoint = getIndexClosestCoordinate(this.midpointCollection.features, point(startingPoint.geometry.coordinates), (a) => a.geometry.coordinates);
                this.listeners[FixedShapeMidpointHandleEvent.DRAG].forEach((listener) => listener(transformation, position, indexOfMovingMidpoint));
            });
        };

        this.mouseMoveListener = this.operations.onMouseMove(contentUnderMouseChanged);

        this.onContentUnderMouseChangedListener = this.operations.onContentUnderMouseChanged(contentUnderMouseChanged);

        this.mouseDownHandleListener = this.operations.onMouseDownHandle((point: Midpoint) => {
            dragFunction(point);
        });
    }

    getTransformation(originalCoordinate: GeoJSON.Position, cursorCoord: { lng: number; lat: number }) {
        if (this.anchorMidpoint === undefined) {
            const indexOfMovingMidpoint = getIndexClosestCoordinate(this.midpointCollection.features, point(originalCoordinate), (a) => a.geometry.coordinates);
            // Anchor will always be two indeces away from moving midpoint
            this.anchorMidpoint = this.midpointCollection.features[(indexOfMovingMidpoint + 2) % 4].geometry.coordinates;
        }
        const newGeometry = resizeRectangleOneDirectionWithMidpoints(this.feature.geometry, this.anchorMidpoint, [cursorCoord.lng, cursorCoord.lat]);
        return { ...this.feature, geometry: newGeometry };
    }

    removeHandles() {
        this.operations.setHandleGeometry({
            type: "FeatureCollection",
            features: [],
        });
        this.mouseDownHandleListener.remove();
    }

    private getCursorBearing(midpointHandleObject: GeoJSON.Feature<GeoJSON.Point>) {
        const midpoint = midpointHandleObject;
        const anchor = this.midpointCollection.features[(getIndexClosestCoordinate(this.midpointCollection.features, midpoint, (a) => a.geometry.coordinates) + 2) % 4];
        const cursorBearing = ensure360Bearing(rhumbBearing(anchor, midpoint));
        return cursorBearing;
    }

    destroy() {
        this.operations.setHandleGeometry({
            type: "FeatureCollection",
            features: [],
        });

        this.mouseDownHandleListener?.remove();
        this.mouseMoveListener.remove();
        this.onContentUnderMouseChangedListener.remove();
        this.mouseUpListener?.remove();
        this.mouseDragListener?.remove();

        this.anchorMidpoint = undefined;
    }

    public on<E extends FixedShapeMidpointHandleEvent>(event: E, callback: ListenerPattern[keyof ListenerPattern][0]) {
        this.listeners[event].push(callback as () => void);
        return this;
    }
}

function getCoordinateGeometry(coordinates: GeoJSON.Position[], fixedShape: FixedShape): GeoJSON.FeatureCollection<GeoJSON.Point> {
    if (coordinates === undefined || coordinates.length === 0) {
        return {
            type: "FeatureCollection",
            features: [],
        };
    }

    const midpoints = getMidpointsRhumb(coordinates);

    // Add the midpoint and the current index
    // If clicked, the midpoint will be added
    // at indexBefore + 1
    const midpointFeatures = midpoints.map(
        (point, index) =>
            feature(point, {
                indexBefore: index,
                fixedShape,
            }) as Midpoint
    );

    return featureCollection(midpointFeatures);
}
