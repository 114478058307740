export class Cacher<T> {
    private values: { [key: string]: T } = {};

    public setEntry(value: T, ...compositeKey: (string | number)[]) {
        this.values[getKey(compositeKey)] = value;
    }

    public getEntry(...compositeKey: (string | number)[]) {
        const key = getKey(compositeKey);
        // eslint-disable-next-line no-prototype-builtins
        const hit = this.values.hasOwnProperty(key);

        if (hit) {
            return this.values[key];
        }

        return null;
    }

    public clear() {
        this.values = {};
    }
}

function getKey(compositeKey: (string | number)[]) {
    return compositeKey.join(".");
}
