/* eslint-disable no-underscore-dangle */
import { Listener } from "@iventis/types";

type KeyboardCallback = (callback: (event: KeyboardEvent) => void) => Listener;

/**
 * Listens to the given keyup and keydown keyboard events, and maintains a "ctrlDown" state
 * which can be read externally to check if the ctrl key is down
 * */
export class CtrlListener {
    private ctrlDownListener: Listener;

    private ctrlUpListener: Listener;

    /** Is the ctrl key currently down */
    private _ctrlDown = false;

    private _active = false;

    private id: string;

    constructor(onKeyUp: KeyboardCallback, onKeyDown: KeyboardCallback, id?: string) {
        this.id = id;
        this._active = true;
        this.ctrlDownListener = onKeyDown((e) => {
            if ((e.ctrlKey || e.metaKey) && !e.repeat) {
                this._ctrlDown = true;
            }
        });
        this.ctrlUpListener = onKeyUp((e) => {
            if (e.key.toLowerCase() === "control" || e.key.toLowerCase() === "meta") {
                this._ctrlDown = false;
            }
        });
    }

    get ctrlDown() {
        return this._ctrlDown;
    }

    get active() {
        return this._active;
    }

    public forceCtrlUp() {
        this._ctrlDown = false;
    }

    public destroy() {
        this._ctrlDown = false;
        this._active = false;
        this.ctrlDownListener.remove();
        this.ctrlUpListener.remove();
    }
}
