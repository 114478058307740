import GeoJSON from "geojson";
import { coordinateDeleteLayerId } from "../bridge/constants";
import { Listener, MoveEvent } from "../types/internal";
import { getRelativePosition } from "./geojson-helpers";

export enum CoordinateDeleteEvent {
    ENTER = "ENTER",
    LEAVE = "LEAVE",
    DELETE = "DELETE",
}

interface ListenerPattern {
    [CoordinateDeleteEvent.ENTER]: (() => void)[];
    [CoordinateDeleteEvent.DELETE]: (() => void)[];
    [CoordinateDeleteEvent.LEAVE]: (() => void)[];
}

export class CoordinateDelete {
    private listeners: ListenerPattern = {
        [CoordinateDeleteEvent.ENTER]: [],
        [CoordinateDeleteEvent.DELETE]: [],
        [CoordinateDeleteEvent.LEAVE]: [],
    };

    private buttonMoveListener: Listener;

    private clickListener: Listener;

    private moveListener: Listener;

    private contentUnderCursorChangedListener: Listener;

    private mouseInButton = false;

    constructor(
        private coordinate: GeoJSON.Position,
        private geometry: GeoJSON.FeatureCollection<GeoJSON.Point>,
        private operations: {
            onDeleteMouseMove: (callback: () => void) => Listener;
            onMouseMove: (callback: (event: MoveEvent) => void) => Listener;
            onContentUnderCursorChanged: (callback: (event: MoveEvent) => void) => Listener;
            onDeleteClick: (callback: () => void) => Listener;
            setGeometry: (position: GeoJSON.FeatureCollection<GeoJSON.Point>) => void;
            getPosition: ReturnType<typeof getRelativePosition>;
        }
    ) {
        const position = this.operations.getPosition(this.geometry, this.coordinate, 17);
        this.setGeometry(position);

        const deleteMouseMove = () => {
            if (!this.mouseInButton) {
                this.listeners.ENTER.forEach((listener) => listener());
                this.mouseInButton = true;
            }
        };

        this.buttonMoveListener = this.operations.onDeleteMouseMove(deleteMouseMove);

        this.contentUnderCursorChangedListener = this.operations.onContentUnderCursorChanged((event) => {
            if (event.objects.find((object) => object.properties.layerid === coordinateDeleteLayerId)) {
                deleteMouseMove();
            }
        });

        this.moveListener = this.operations.onMouseMove((event: MoveEvent) => {
            if (!event.objects.some((object) => object.properties.layerid === coordinateDeleteLayerId) && this.mouseInButton) {
                // No longer over the delete button
                this.listeners.LEAVE.forEach((listener) => listener());
                this.mouseInButton = false;
            }
        });

        this.clickListener = this.operations.onDeleteClick(() => {
            this.listeners.DELETE.forEach((listener) => listener());
        });
    }

    private setGeometry(position: GeoJSON.Position) {
        const collection: GeoJSON.FeatureCollection<GeoJSON.Point> = {
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Point",
                        coordinates: position,
                    },
                },
            ],
        };
        this.operations.setGeometry(collection);
    }

    public on<E extends CoordinateDeleteEvent>(event: E, callback: ListenerPattern[keyof ListenerPattern][0]) {
        this.listeners[event].push(callback);
        return this;
    }

    public destroy() {
        this.buttonMoveListener.remove();
        this.clickListener.remove();
        this.moveListener.remove();
        this.operations.setGeometry({ type: "FeatureCollection", features: [] });
        this.contentUnderCursorChangedListener?.remove();
    }
}
