import GeoJSON from "geojson";
import { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { LocalGeoJson, MapModuleLayer, MapObjectProperties } from "../../../types/store-schema";
import { MapboxEngine } from "../engine-mapbox";
import { StylePropertyToValueMap } from "../../../types/internal";
import { ModelGeometry } from "./engine-3d-types";
import { ModelLayerStyle } from "../../../types/models";

export abstract class Engine3D<TLayer> {
    constructor(map: Map, assetOptions: MapboxEngine["assetOptions"], currentLevel: number, dateFilterEnabled: boolean) {
        this.map = map;
        this.assetOptions = assetOptions;
        this.currentLevel = currentLevel;
        this.dateFilterEnabled = dateFilterEnabled;
    }

    public currentLevel: number;

    public readonly map: Map;

    public readonly assetOptions: MapboxEngine["assetOptions"];

    public dateFilterEnabled = false;

    /** Iventis layer id to an array of engine specific layers */
    public layers: { [layerId: string]: TLayer[] } = {};

    public abstract updateStyle(layerId: string, style: ModelLayerStyle, styleChanges: StylePropertyToValueMap[]): void;

    public abstract doesLayerExist(id: string): boolean;

    public abstract updateLayerVisibility(id: string, visible: boolean): void;

    public abstract createLayer(id: string, style: ModelLayerStyle, visible: boolean, layerName: string): TLayer[];

    public abstract deleteLayer(id: string): void;

    public abstract deleteAllLayers(): void;

    public abstract setCursor(): void;

    public abstract queryRenderedFeatures(): MapboxGeoJSONFeature[];

    public abstract updateMapObjects(layer: MapModuleLayer, collection: GeoJSON.FeatureCollection<ModelGeometry, MapObjectProperties>): void;

    public abstract setCurrentLevel(currentLevel: number, allModelMapObjects: LocalGeoJson, layers: MapModuleLayer[]): void;

    public abstract updateModelMapOrder(id: string, aboveLayerId: string): void;

    public abstract destroy(): void;
}
