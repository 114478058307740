import { Model } from "@iventis/domain-model/model/model";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { getStaticStyleValue } from "../../../utilities/static-styles";
import { MapObject3DScale, MapObject3DScaleStatic, Scale } from "./engine-3d-scale-types";
import { ModelLayerStyle } from "../../../types/models";
import { DEFAULT_LIST_ITEM_ID } from "./deckgl/engine-deckgl-helpers";

export function createStaticScaleValue(style: ModelLayerStyle, model: Model): Scale {
    if (isModelValidForVariableScale(model)) {
        const { height: modelHeight, length: modelLength, width: modelWidth } = model;
        return [
            calculateModelScaleValue(style?.width?.staticValue?.staticValue ?? 1, modelWidth),
            calculateModelScaleValue(style?.height?.staticValue?.staticValue ?? 1, modelHeight),
            calculateModelScaleValue(style?.length?.staticValue?.staticValue ?? 1, modelLength),
        ];
    }
    return defaultScale.value;
}

export function createAttributeScaleValue(style: ModelLayerStyle, listValueId: string, model: Model): Scale {
    if (isModelValidForVariableScale(model)) {
        const { height: modelHeight, length: modelLength, width: modelWidth } = model;
        const widthScale = calculateModelScaleValue(style.width.mappedValues[listValueId].staticValue, modelWidth);
        const heightScale = calculateModelScaleValue(style.height.mappedValues[listValueId].staticValue, modelHeight);
        const lengthScale = calculateModelScaleValue(style.length.mappedValues[listValueId].staticValue, modelLength);
        return [widthScale, heightScale, lengthScale];
    }
    return defaultScale.value;
}

/**
 * Ensures the dimension value in the style is not 0
 * Calculates the model scale value
 */
export function calculateModelScaleValue(styleDimensionValue: number, modelDimension: number) {
    const validDimension = styleDimensionValue !== 0 ? styleDimensionValue : modelDimension;
    return validDimension / modelDimension;
}

export function createScaleValueForStatic(model: Model, style: ModelLayerStyle): MapObject3DScale {
    return { type: "static", value: createStaticScaleValue(style, model) };
}

export function createScaleValueForNonVariableScaleModel(style: ModelLayerStyle): MapObject3DScale {
    const scale = getStaticStyleValue(style.scale);
    return { type: "static", value: [scale, scale, scale] };
}

export function createScaleValueForListItems(model: Model, style: ModelLayerStyle): MapObject3DScale {
    const listItemIdsToDimensions: { [listItemId: string]: Scale } = {};

    Object.keys({ ...style.height.mappedValues, ...style.width.mappedValues, ...style.length.mappedValues }).forEach((listValueId) => {
        listItemIdsToDimensions[listValueId] = createAttributeScaleValue(style, listValueId, model);
    });

    listItemIdsToDimensions.default = createStaticScaleValue(style, model);
    return { type: "attribute", value: listItemIdsToDimensions, dataFieldId: getModelScaleAttributeBasedId(style.height) };
}

export function updateScaleValueForListItems(style: ModelLayerStyle, listItemIdsToDimensions: MapObject3DScale, listValueId: string, model: Model): MapObject3DScale {
    // If previous value is static we need to create a new value
    if (listItemIdsToDimensions == null || listItemIdsToDimensions.type === "static") {
        return createScaleValueForListItems(model, style);
    }

    const updatedListItemIdsToDimensions: MapObject3DScale = { ...listItemIdsToDimensions };

    if (listValueId === DEFAULT_LIST_ITEM_ID) {
        updatedListItemIdsToDimensions.value.default = createStaticScaleValue(style, model);
        return updatedListItemIdsToDimensions;
    }

    updatedListItemIdsToDimensions.value[listValueId] = createAttributeScaleValue(style, listValueId, model);

    return updatedListItemIdsToDimensions;
}

export const isModelValidForVariableScale = (model: Model) => model?.height != null && !checkNullOr0(model.height) && !checkNullOr0(model.width) && !checkNullOr0(model.length);

export const isModelDimensionsAttributeBased = (height: ModelLayerStyle["height"]) => height?.extractionMethod === StyleValueExtractionMethod.Mapped;

export const getModelScaleAttributeBasedId = (height: ModelLayerStyle["height"]) => height.dataFieldId;

const checkNullOr0 = (value: number) => value === 0 || value == null;

export const defaultScale: MapObject3DScaleStatic = { type: "static", value: [1, 1, 1] };
