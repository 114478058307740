import { StyleType } from "@iventis/domain-model/model/styleType";
import { AnyLayer } from "mapbox-gl";
import { MapModuleLayer } from "../../../types/store-schema";
import { AggregateLayers, SubLayerType } from "../sublayer-types";
import { getAggregateLayerBySubLayerId, isAggregateSubLayerLocal } from "../engine-mapbox-helpers";
import { commentsLayerId, internalMapLayerIds } from "../../constants";
import { SubLayerPlacement } from "./layer-ordering-types-and-constants";
import { getRemoteLayerId, removeLayerIdSuffixes } from "../../engine-generic";
import { getLocalLayerID } from "../../engine-generic-helpers";
import { orderDomainLayers } from "./layer-ordering-generic-helpers";

/** Gets the layer id which is above a given layer in the map */
export function getAboveLayerId(subLayerId: string, aggregateLayers: AggregateLayers, mapboxLayers: AnyLayer[], mapModuleLayers: MapModuleLayer[], remoteBaseLayerId: string) {
    if (commentsLayerId === removeLayerIdSuffixes(subLayerId)) {
        return getBottomMostInternalLayerId(mapboxLayers);
    }

    const isLocalLayer = isAggregateSubLayerLocal(subLayerId, aggregateLayers);
    if (isLocalLayer) {
        return getAboveLayerIdForLocalLayer(subLayerId, aggregateLayers);
    }
    return getAboveLayerIdForRemoteLayer(subLayerId, remoteBaseLayerId, aggregateLayers, mapboxLayers, mapModuleLayers);
}

/** Gets the above layer id for a remote layer */
function getAboveLayerIdForRemoteLayer(subLayerId: string, layerId: string, aggregateLayers: AggregateLayers, mapboxLayers: AnyLayer[], mapModuleLayers: MapModuleLayer[]) {
    // Check if the layer is placed below the base layer, if so return the base layer
    const subLayer = aggregateLayers[layerId].find((layer) => layer.id === subLayerId);
    const placement = isSubLayerAboveOrBelow(subLayer.type, false);
    if (placement === SubLayerPlacement.Below) {
        return aggregateLayers[layerId].find((l) => l.type === SubLayerType.BASE)?.id;
    }

    // Find the layer above the given layer in ordered map layers
    const orderedMapModuleLayers = orderDomainLayers(mapModuleLayers);
    const index = orderedMapModuleLayers.findIndex((layer) => layer.id === layerId);
    const aboveLayer = orderedMapModuleLayers[index + 1];

    if (aboveLayer == null) {
        return getBottomMostInternalLayerId(mapboxLayers);
    }

    return getBottomMostRelatedLayer(aggregateLayers, aboveLayer?.id, mapboxLayers, mapModuleLayers) ?? getBottomMostInternalLayerId(mapboxLayers);
}

/** Gets the above layer id for a local layer */
function getAboveLayerIdForLocalLayer(localLayerId: string, aggregateLayers: AggregateLayers) {
    // Get the base layer of the layer being added to the map
    const localAggregateLayer = getAggregateLayerBySubLayerId(aggregateLayers, localLayerId);
    const layerSubLayerType = localAggregateLayer.find((layer) => layer.id === localLayerId);
    const localBaseLayer = localAggregateLayer.find((layer) => layer.type === SubLayerType.BASE || layer.type === SubLayerType.EXTRUSION);

    // If layer is placed below the base layer, then return the base layer id
    const placement = isSubLayerAboveOrBelow(layerSubLayerType.type, localBaseLayer == null);
    if (placement === SubLayerPlacement.Below) {
        return localBaseLayer?.id;
    }

    // Get the base remote sub layers and then find the equivalent type of the layer being added to the map
    const remoteBaseLayerId = getRemoteLayerId(localBaseLayer.id);
    const remoteAggregateLayer = aggregateLayers[remoteBaseLayerId];
    const remoteSubLayer = remoteAggregateLayer.find((layer) => layer.type === layerSubLayerType.type);
    return remoteSubLayer.id;
}

/** Returns whether a sub layer is above or below the base layer */
export function isSubLayerAboveOrBelow(subLayerType: SubLayerType, isBaseLayerMissing = false) {
    if (isBaseLayerMissing) {
        return SubLayerPlacement.Above;
    }

    switch (subLayerType) {
        case SubLayerType.LINE_ICON:
        case SubLayerType.LINE_TEXT:
        case SubLayerType.POLYGON_TEXT:
        case SubLayerType.POINT_TEXT:
        case SubLayerType.EXTRUSION:
        case SubLayerType.LINE_MODEL:
            return SubLayerPlacement.Above;
        case SubLayerType.LINE_OUTLINE:
        case SubLayerType.POLYGON_OUTLINE:
            return SubLayerPlacement.Below;
        case SubLayerType.BASE:
            return SubLayerPlacement.IsBaseLayer;
        default:
            throw new Error(`${subLayerType} not handled`);
    }
}

/** Returns the id of the bottom most layer in the map (for example if a line has an outline layer it will return it's id) */
export function getBottomMostRelatedLayer(aggregateLayers: AggregateLayers, layerId: string, mapboxLayers: AnyLayer[], mapModuleLayers: MapModuleLayer[] = []) {
    // Local layer will always be below the remote layer
    const layers = aggregateLayers[getLocalLayerID(layerId)] ?? aggregateLayers[layerId];

    if (layers == null) {
        return undefined;
    }

    const mapModuleLayer = mapModuleLayers.find((layer) => layer.id === layerId);

    // Models can have multiple base layers (data driven styling) so find the bottom most one (first one in array)
    if (mapModuleLayer && mapModuleLayer.styleType === StyleType.Model) {
        const baseModel = layers.find(({ type }) => SubLayerType.BASE === type);
        return baseModel?.id;
    }

    // Line models can have multiple line models (data driven styling) so find the bottom most one (first one in array)
    if (mapModuleLayer && mapModuleLayer.styleType === StyleType.LineModel) {
        const lineModel = layers.find(({ type }) => SubLayerType.LINE_MODEL === type);
        return lineModel?.id;
    }

    // Get all ids relating to above layer (includes borders, text etc.)
    const allRelatedSubLayerIds = layers.map((layer) => layer.id);
    // Find the bottom most id of all the related layers
    const bottomMostLayer = mapboxLayers.find((layer) => allRelatedSubLayerIds.includes(layer.id));

    return bottomMostLayer?.id;
}

/** Finds the bottom most internal layer which could be drawing nodes, rotation handle etc. */
export function getBottomMostInternalLayerId(mapboxLayers: AnyLayer[]) {
    const internalLayer = mapboxLayers.find((layer) => internalMapLayerIds.includes(layer.id));
    return internalLayer?.id;
}
