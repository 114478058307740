/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { MapboxLayer } from "@deck.gl/mapbox";
import { Deck } from "deck.gl";
import { Map, MapboxGeoJSONFeature, Layer } from "mapbox-gl";
import { Point, LineString, FeatureCollection, Feature } from "geojson";
import { load } from "@loaders.gl/core";
import { GLTFLoader } from "@loaders.gl/gltf";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { featureCollection } from "@turf/helpers";
import { v4 as uuid } from "uuid";
import { ScenegraphLayer } from "@deck.gl/mesh-layers";
import { Model } from "@iventis/domain-model/model/model";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { StylePropertyToValueMap } from "../../../../types/internal";
import { LocalGeoJson, MapModuleLayer, MapObjectProperties, ModelData, UnionOfStyles } from "../../../../types/store-schema";
import { getStaticStyleValue } from "../../../../utilities/static-styles";
import { Engine3D } from "../engine-3d-interface";
import { MapObject3D, ModelGeometry } from "../engine-3d-types";
import { DEFAULT_LIST_ITEM_ID, getAttributeBasedScaleValue, getModelAndListItemId, isModelAttributeBased, parseGeoJsonToMapObject3D } from "./engine-deckgl-helpers";
import { createFeaturesOnLine } from "../3d-engine-helpers";
import { MapObject3DScale } from "../engine-3d-scale-types";
import {
    createScaleValueForListItems,
    createScaleValueForNonVariableScaleModel,
    createScaleValueForStatic,
    defaultScale,
    isModelDimensionsAttributeBased,
    isModelValidForVariableScale,
    updateScaleValueForListItems,
} from "../3d-engine-scale-helpers";
import { ModelLayerStyle } from "../../../../types/models";
import { getModelLayerStyle } from "../../../../utilities/layer.helpers";
import { getDefaultStyleProperty } from "../../../../utilities/style-helpers";
import { MapboxEngine } from "../../engine-mapbox";

export class DeckglEngine extends Engine3D<MapboxLayer<MapObject3D>> {
    private mapObjectsMouseIsOver: MapObject3D;

    public readonly map: Map & { __deck: Deck };

    /** Ensure models are facing the same way as Threebox */
    private readonly rotationModifier = 180;

    /** Holds the precalculated scale values for each layer */
    private layerToScaleValue: { [layerId: string]: MapObject3DScale } = {};

    /** Deckgl layer id to the model it is using */
    private deckglLayerIdToModel: { [deckglLayerId: string]: Model } = {};

    /** Model Asset Id (main/thumbnail) to model LOD asset id */
    private modelIdToModelLodId: { [assetId: string]: string } = {};

    /** Key value pair of the models which have already been or are being loaded */
    private loadedOrRequestedModels: Record<string, ModelData> = {};

    constructor(map: Map, assetOptions: MapboxEngine["assetOptions"], currentLevel: number, models: ModelData[], dateFilterEnabled: boolean) {
        super(map, assetOptions, currentLevel, dateFilterEnabled);
        models.forEach((model) => {
            this.loadedOrRequestedModels[model.id] = { ...model };
        });
    }

    /**
     *  Creates an array of deckgl layers, one for each different model used for the layer.
     *
     *  For example an Iventis layer which has attribute based styling with 3 list values would have 4 deckgl layers (3 list items and default value)
     */
    public createLayer(id: string, style: ModelLayerStyle, visible: boolean, layerName: string): MapboxLayer<MapObject3D>[] {
        if (this.doesLayerExist(id)) {
            return this.getDeckglLayersFromIventisLayer(id);
        }

        const attributeId = style.model.dataFieldId;
        const layers = getModelAndListItemId(style).map(({ listItemId, modelId }) => this.createDeckglLayer(id, modelId, visible, style, layerName, listItemId, attributeId));
        this.layers[id] = layers;
        layers.forEach((layer) =>
            this.createLayerScaleValues(this.loadedOrRequestedModels[layer.props.metaData.modelId], style, layer.props.metaData.layerId, layer.props.metaData.modelListItemId)
        );
        return layers;
    }

    private createDeckglLayer(layerId: string, modelId: string, visible: boolean, style: ModelLayerStyle, layerName: string, listItemId?: string, modelAttributeId?: string) {
        const deckglLayerId = uuid();
        return new MapboxLayer({
            id: deckglLayerId,
            type: ScenegraphLayer,
            pickable: true,
            data: [],
            scenegraph: this.loadModel(modelId, deckglLayerId),
            getPosition: (d: MapObject3D) => d.position,
            getOrientation: (d: MapObject3D) => [0, d.rotation.z + this.rotationModifier, 90],
            getScale: (d: MapObject3D) => d.scale,
            visible,
            _lighting: "pbr",
            onHover: (event) => this.onLayerHover(event.object),
            metaData: { modelListItemId: listItemId, modelAttributeId, layerName, layerId, styleType: style.styleType, modelId },
        });
    }

    /** Deletes the layer and the associated scale value (does not remove the layer from the map) */
    public deleteLayer(iventisLayerId: string): void {
        delete this.layers[iventisLayerId];
        delete this.layerToScaleValue[iventisLayerId];
    }

    /** Deletes all model layers and removes them from the map */
    public deleteAllLayers(): void {
        Object.entries(this.layers).forEach(([iventisLayerId, layers]) => {
            this.deleteLayer(iventisLayerId);
            layers.forEach((layer) => {
                if (this.map.getLayer(layer.id)) {
                    this.map.removeLayer(layer.id);
                }
            });
        });
    }

    public doesLayerExist(layerId: string): boolean {
        return this.layers[layerId] != null;
    }

    /**
     * Updates all deckgl layers associated to the layerId passed in
     *
     * Note: Rotation and spacing require the updateMapObjects to be called
     */
    public updateStyle<TStyle extends UnionOfStyles = UnionOfStyles>(iventisLayerId: string, style: ModelLayerStyle, styleChanges: StylePropertyToValueMap<TStyle>[]): void {
        if (!(style.styleType === StyleType.Model || style.styleType === StyleType.LineModel)) {
            throw new Error("Style type is not supported");
        }

        styleChanges.forEach(({ styleProperty }) => {
            switch (styleProperty) {
                case "model": {
                    // If model is styled by an attribute value
                    if (isModelAttributeBased(style.model)) {
                        // Get list item id and model which is being represented by it
                        getModelAndListItemId(style).forEach(({ listItemId, modelId }) => {
                            // Get the deckgl layer
                            const layer = this.getDeckglLayersFromIventisLayer(iventisLayerId).find((layer) => layer.props.metaData.modelListItemId === listItemId);
                            // Update the layer being used for that layer
                            layer.setProps({
                                scenegraph: this.loadModel(modelId, layer.id),
                                metaData: { ...layer.props.metaData, modelId },
                            });
                            this.createLayerScaleValues(
                                this.loadedOrRequestedModels[layer.props.metaData.modelId],
                                style,
                                layer.props.metaData.layerId,
                                layer.props.metaData.modelListItemId
                            );
                        });
                    } else {
                        const layers = this.getDeckglLayersFromIventisLayer(iventisLayerId);
                        const modelId = getStaticStyleValue(style.model);
                        layers.forEach((layer) => {
                            layer.setProps({
                                scenegraph: this.loadModel(modelId, layer.id),
                                metaData: { ...layer.props.metaData, modelId },
                            });
                            this.createLayerScaleValues(
                                this.loadedOrRequestedModels[layer.props.metaData.modelId],
                                style,
                                layer.props.metaData.layerId,
                                layer.props.metaData.modelListItemId
                            );
                        });
                    }

                    break;
                }
                case "rotation":
                case "spacing":
                case "modelOffset":
                case "objectOrder":
                    // Above values need to update the properties of the 3d map objects instead of changing something on the layer
                    break;
                case "scale":
                case "length":
                case "width":
                case "height":
                    // For each deckgl layer which represents the Iventis layer, change it's dimensions
                    this.getDeckglLayersFromIventisLayer(iventisLayerId).forEach((layer) => {
                        this.createLayerScaleValues(this.deckglLayerIdToModel[layer.id], style, iventisLayerId, layer.props.metaData.modelListItemId);
                        this.updateLayerScaleValue(iventisLayerId);
                    });
                    break;
                default:
                    throw new Error(`Style property "${String(styleProperty)}" not supported for style type "${style.styleType}"`);
            }
        });
    }

    public updateLayerVisibility(id: string, visible: boolean) {
        const layers = this.getDeckglLayersFromIventisLayer(id);
        layers.forEach((layer) => layer.setProps({ visible }));
    }

    /** Updates the map object model positions on the map for the given layer and features */
    public updateMapObjects(iventisLayer: MapModuleLayer, collection: FeatureCollection<ModelGeometry, MapObjectProperties>): void {
        const deckglLayers = this.getDeckglLayersFromIventisLayer(iventisLayer.id);
        // Get attribute and list items which are used to display the model
        const modelListItemIds = deckglLayers.map((layer) => layer.props.metaData.modelListItemId);
        const { modelAttributeId } = deckglLayers[0].props.metaData;

        // Scale value is null due to model not being loaded. Use default scale.
        // Once model has loaded scale will be calculated and applied to the layer
        if (this.layerToScaleValue[iventisLayer.id] == null) {
            this.layerToScaleValue[iventisLayer.id] = defaultScale;
        }

        // Create a key value pair of list item id to an array of map objects
        const updatedMapObjects =
            iventisLayer.styleType === StyleType.Model
                ? this.updateMapObjectForModel(iventisLayer, collection, modelListItemIds, modelAttributeId)
                : this.updateMapObjectsForLineModel(iventisLayer, collection.features, modelListItemIds, modelAttributeId);

        // For each layer which represents the Iventis layer get it's update map objects and set the updated data
        deckglLayers.forEach((layer) => {
            const { modelListItemId } = layer.props.metaData;
            const features = updatedMapObjects[modelListItemId];
            layer.setProps({ data: features });
        });
    }

    /** Creates key value pair of model list item id to an array of map object models for a line model */
    private updateMapObjectsForLineModel(
        iventisLayer: MapModuleLayer,
        features: Feature<ModelGeometry, MapObjectProperties>[],
        modelListItemIds: string[],
        modelAttributeId: string
    ) {
        const lineFeatures = createFeaturesOnLine(
            iventisLayer.id,
            features as Feature<LineString, MapObjectProperties>[],
            iventisLayer.lineModelStyle.spacing ?? getDefaultStyleProperty(StyleType.LineModel, "spacing"),
            iventisLayer.lineModelStyle.rotation ?? getDefaultStyleProperty(StyleType.LineModel, "rotation"),
            iventisLayer.lineModelStyle.modelOffset ?? getDefaultStyleProperty(StyleType.LineModel, "modelOffset")
        );
        return parseGeoJsonToMapObject3D(
            lineFeatures,
            iventisLayer.id,
            this.layerToScaleValue[iventisLayer.id],
            this.currentLevel,
            modelListItemIds,
            iventisLayer.name,
            this.dateFilterEnabled,
            modelAttributeId
        );
    }

    /** Creates key value pair of model list item id to an array of map object models for a model */
    private updateMapObjectForModel(
        layer: MapModuleLayer,
        collection: FeatureCollection<ModelGeometry, MapObjectProperties>,
        modelListItemIds: string[],
        modelAttributeId: string
    ) {
        return parseGeoJsonToMapObject3D(
            collection as FeatureCollection<Point, MapObjectProperties>,
            layer.id,
            this.layerToScaleValue[layer.id],
            this.currentLevel,
            modelListItemIds,
            layer.name,
            this.dateFilterEnabled,
            modelAttributeId
        );
    }

    public setCursor(): void {
        if (this.map.__deck) {
            this.map.__deck.props.getCursor = () => this.map.getCanvas().style.cursor;
        }
    }

    /** Returns the last model map object that the mouse as over */
    public queryRenderedFeatures(): MapboxGeoJSONFeature[] {
        if (this.mapObjectsMouseIsOver == null) {
            return [];
        }
        const returnValue: MapboxGeoJSONFeature[] = [
            {
                id: this.mapObjectsMouseIsOver.id,
                properties: this.mapObjectsMouseIsOver.properties,
                geometry: { coordinates: this.mapObjectsMouseIsOver.position, type: "Point" },
                layer: { id: this.mapObjectsMouseIsOver.layerId } as Layer,
                type: "Feature",
                source: undefined,
                sourceLayer: undefined,
                state: undefined,
            },
        ];
        return returnValue;
    }

    private onLayerHover(mapObject: MapObject3D) {
        this.mapObjectsMouseIsOver = mapObject;
    }

    /** When a model is loaded for a layer need to create scale values which are based upon the layer styles (height, width and length) and the model dimensions */
    private createLayerScaleValues(model: Model, style: ModelLayerStyle, layerId: string, listItemId?: string) {
        switch (true) {
            // Model style value are set by attribute
            case isModelAttributeBased(style.model) && this.layerToScaleValue[layerId] != null:
                this.layerToScaleValue[layerId] = updateScaleValueForListItems(style, this.layerToScaleValue[layerId], listItemId, model);
                break;
            // Length, width and height are set by attribute
            case isModelDimensionsAttributeBased(style.height):
                this.layerToScaleValue[layerId] = createScaleValueForListItems(model, style);
                break;
            // Model being used by layer can have variable dimensions
            case isModelValidForVariableScale(model):
                this.layerToScaleValue[layerId] = createScaleValueForStatic(model, style);
                break;
            // Normal model layer with no attribute based styling
            default:
                this.layerToScaleValue[layerId] = createScaleValueForNonVariableScaleModel(style);
                break;
        }
        this.updateLayerScaleValue(layerId);
    }

    /** Gets a layer and updates all the map objects belonging to it with the latest scale values */
    private updateLayerScaleValue(layerId: string) {
        const updatedScaleValue = this.layerToScaleValue[layerId];
        const layers = this.getDeckglLayersFromIventisLayer(layerId);
        layers.forEach((layer) => {
            const updatedData = layer.props.data.map((d) => ({
                ...d,
                scale: updatedScaleValue.type === "static" ? updatedScaleValue.value : getAttributeBasedScaleValue(updatedScaleValue, d.properties),
            }));
            layer.setProps({
                data: updatedData,
            });
        });
    }

    /** Checks if a model has been loaded, is being loaded or has not been requested yet */
    public async loadModel(modelId: string, deckglLayerId: string) {
        const model = this.loadedOrRequestedModels[modelId];
        if (model == null) {
            return this.requestModel(modelId, deckglLayerId);
        }
        return this.getLoadedModel(model, modelId, deckglLayerId);
    }

    /** If a model is currently loading or has previously been loaded */
    private async getLoadedModel(model: ModelData, modelId: string, deckglLayerId: string) {
        this.deckglLayerIdToModel[deckglLayerId] = model;
        this.modelIdToModelLodId[modelId] = model.lods[0].files[0].assetId;

        return this.addModelToMap(model.modelRequest);
    }

    /** If a model has not been requested yet */
    private async requestModel(modelId: string, deckglLayerId: string) {
        // Get the asset which contains the model asset url
        const [model] = await this.assetOptions.multipleModelsGetter([modelId]);

        this.deckglLayerIdToModel[deckglLayerId] = model;
        const { assetId } = model.lods[0].files[0];
        this.modelIdToModelLodId[modelId] = assetId;

        // Get the model asset url and then load the model
        const getModel = async () => {
            const modelUrl = await this.assetOptions.assetUrlGetter(assetId, AssetType.Model);
            const response = await fetch(modelUrl);
            const modelGlb = await response.arrayBuffer();
            return modelGlb;
        };

        // Added
        const loadingModel = { ...model, modelRequest: getModel() };
        this.loadedOrRequestedModels[modelId] = loadingModel;

        return this.addModelToMap(loadingModel.modelRequest);
    }

    /** Waits for a model to load, if it hasn't already loaded and then adds it to the map */
    private async addModelToMap(model: Promise<ArrayBuffer> | ArrayBuffer) {
        // If the model is still loading wait until it has resolved
        if (model instanceof Promise) {
            return model.then((response) => load(response, GLTFLoader));
        }
        // Model has finished loading so add to the deckgl layer
        return load(model, GLTFLoader);
    }

    /** Filters out map object models which do not have the currentLevel value */
    public setCurrentLevel(currentLevel: number, allModelMapObjects: LocalGeoJson, layers: MapModuleLayer[]): void {
        this.currentLevel = currentLevel;
        layers.forEach((layer) => {
            const features = allModelMapObjects[layer.id];
            const layerFeatureCollection = featureCollection(features.map((feature) => feature.feature as Feature<Point, MapObjectProperties>));
            this.updateMapObjects(layer, layerFeatureCollection);
        });
    }

    /** Checks if a layer model is styled by attributes and if all list items are represented by a deckgl layer */
    public doesLayerNeedRecreating(layer: MapModuleLayer) {
        const style = getModelLayerStyle(layer);
        // Only need to recreate layers where the model is attribute based on the model
        if (style.model.extractionMethod !== StyleValueExtractionMethod.Mapped) {
            return false;
        }
        // Get all of the deckgl layers and list value ids for that layer
        // Ensure that there is one layer per list item and the default value
        const deckglLayers = this.getDeckglLayersFromIventisLayer(layer.id);
        const listItemIds = [...Object.keys(style.model.mappedValues ?? {}), DEFAULT_LIST_ITEM_ID];
        const output = listItemIds.length === deckglLayers.length && deckglLayers.every((deckglLayer) => listItemIds.includes(deckglLayer.props.metaData.modelListItemId));
        return !output;
    }

    /** Getters */

    /** Get the Iventis layer from the deckgl layer Id */
    public getIventisLayerId(layerId: string) {
        return Object.keys(this.layers).find((deckglLayerId) => this.layers[deckglLayerId].some(({ id }) => layerId === id));
    }

    /** Return an array of deckgl layers which represent the Iventis layer */
    private getDeckglLayersFromIventisLayer(layerId: string): MapboxLayer<MapObject3D>[] {
        const layer = this.layers[layerId];
        if (layer == null) {
            throw Error(`${layerId} is not a layer in deckgl engine`);
        }
        return layer;
    }

    /** Get the LOD model asset ID from the model assetId */
    public getModelLodIdFromModelId(assetId: string) {
        return this.modelIdToModelLodId[assetId];
    }

    public removeModelFromLoadedModels(modelId: string) {
        delete this.loadedOrRequestedModels[modelId];
    }

    public updateModelMapOrder(id: string, aboveLayerId: string): void {
        this.layers[id].forEach((layer) => {
            this.map.moveLayer(layer.id, aboveLayerId);
        });
    }

    public readonly _testFunctions = {
        getLayerByName: (layerName: string) => {
            const allDeckglLayers = Object.values(this.layers).flat();
            return allDeckglLayers.filter((layer) => layer.props.metaData.layerName === layerName);
        },
        getLayerGeoJsonFeatures: (layerName: string) => {
            const layers = this._testFunctions.getLayerByName(layerName);
            const mapObjects = layers.map((layer) => layer.props.data);
            return mapObjects.map((mo) => mo.map((m) => ({ type: "Feature", properties: m.properties, geometry: { type: "Point", coordinates: m.position } }))).flat();
        },
    };

    public destroy(): void {
        if (this.map.__deck) {
            this.map.__deck.finalize();
        }
    }
}
