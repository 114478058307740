import { TimeSpan, Preposition } from "@iventis/types";
import { add, format, intervalToDuration, sub, Duration, differenceInSeconds } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import formatDuration from "date-fns/formatDuration";

/**
 * Regex to match hh:mm:ss or -hh:mm:ss pattern.
 */
export const timeSpanRegex = /^-?(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;

export const isValidTimeSpan = (timespan: string) => timespan?.match(timeSpanRegex) != null;

export const isValidTimeRange = (timeRange: string) => {
    const [from, to] = timeRange.split("-");
    return isValidTimeSpan(from) && isValidTimeSpan(to);
};

/**
 * Add a time span to a given date.
 */
export const addTimeSpanToDate = (timeSpan: TimeSpan, date: Date) => {
    let addTimeSpan = add;
    if (timeSpan?.startsWith("-")) {
        addTimeSpan = sub;
    }
    const timeSpanSplit = timeSpan?.split(":")?.map((n) => Math.abs(Number(n)));
    return addTimeSpan(date, { hours: timeSpanSplit[0], minutes: timeSpanSplit[1], seconds: timeSpanSplit[2] });
};

// Take away a timespan from a given date.
export const takeAwayTimeSpanFromDate = (timeSpan: TimeSpan, date: Date) => {
    let takeAwayTimeSpan = sub;
    if (timeSpan?.startsWith("-")) {
        takeAwayTimeSpan = add;
    }
    const timeSpanSplit = timeSpan?.split(":")?.map((n) => Math.abs(Number(n)));
    return takeAwayTimeSpan(date, { hours: timeSpanSplit[0], minutes: timeSpanSplit[1], seconds: timeSpanSplit[2] });
};

/**
 * Add a number of seconds to a time span
 */
export const addSecondsToTimeSpan = (timeSpan: TimeSpan, changeInSeconds: number): TimeSpan => {
    const timeSpanSplit = timeSpan?.split?.(":")?.map((n) => Math.abs(Number(n)));
    const duration = { hours: timeSpanSplit[0], minutes: timeSpanSplit[1], seconds: timeSpanSplit[2] };
    const seconds = format(add(0, duration), "t");
    const newTimeSpanInSeconds = (timeSpan?.startsWith("-") ? -Number(seconds) : Number(seconds)) + changeInSeconds;
    const newDuration = intervalToDuration({ start: 0, end: Math.abs(newTimeSpanInSeconds) * 1000 });
    return `${newTimeSpanInSeconds < 0 ? "-" : ""}${durationToTimeSpan(newDuration)}`;
};

/**
 * Convert a number of minutes to a timespan
 */
export const minutesToTimeSpan = (mins: number): TimeSpan => {
    const duration = intervalToDuration({ start: 0, end: mins * 60 * 1000 });
    return `${mins < 0 ? "-" : ""}${durationToTimeSpan(duration)}`;
};

/**
 * Given a duration, covert to a timespan
 */
export const durationToTimeSpan = ({ hours, minutes, seconds }: Duration): TimeSpan => `${formatUnit(hours)}:${formatUnit(minutes)}:${formatUnit(seconds)}`;

/**
 * Given a duration, covert to a timespan
 */
export const timeSpanToDuration = (timeSpan: TimeSpan): Duration =>
    timeSpan?.split(":")?.reduce<Duration>((acc, curr, index) => ({ ...acc, [index === 0 ? "hours" : index === 1 ? "minutes" : "seconds"]: Number(curr) }), {});

/**
 * Given a time span, produce the time difference. (ie Add the + sign)
 */
export const timeSpanToTimeDifference = (timeSpan: TimeSpan) => (timeSpan.startsWith("-") ? timeSpan : `+${timeSpan}`);

/**
 * Format a number into into 00 if singular digit.
 */
export const formatUnit = (unit: number): string => (unit < 10 ? `0${unit}` : unit.toString());

/**
 * Invert the zero time. + becomes -. - becomes +.
 */
export const invertZeroTimeSpan = (zeroTimeSpan: TimeSpan): TimeSpan => (zeroTimeSpan.startsWith("-") ? (zeroTimeSpan.substring(1) as TimeSpan) : (`-${zeroTimeSpan}` as TimeSpan));

/**
 * Format a time span
 */
export const formatTimeSpan = (preposition: Preposition, hours: number | string, minutes: number | string, seconds: number | string): TimeSpan => {
    const formattedHours = typeof hours === "number" ? formatUnit(hours) : hours;
    const formattedMinutes = typeof minutes === "number" ? formatUnit(minutes) : minutes;
    const formattedSeconds = typeof seconds === "number" ? formatUnit(seconds) : seconds;
    return `${preposition === Preposition.Before ? "-" : ""}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

/**
 * Returns the time difference with a + or - sign at the front.
 */
export const timeDifference = (first: Date, second: Date) => {
    let differenceInSecondsStartToZeroTime = differenceInSeconds(new Date(first), new Date(second));
    let preposition: Preposition;
    let sign = "";
    if (differenceInSecondsStartToZeroTime < 0) {
        preposition = Preposition.Before;
        differenceInSecondsStartToZeroTime = Math.abs(differenceInSecondsStartToZeroTime);
    } else {
        preposition = Preposition.After;
        sign = "+";
    }
    const hours = Math.floor(differenceInSecondsStartToZeroTime / 3600);
    const minutes = Math.floor((differenceInSecondsStartToZeroTime % 3600) / 60);
    const seconds = (differenceInSecondsStartToZeroTime % 3600) % 60;
    return sign + formatTimeSpan(preposition, hours, minutes, seconds);
};

/**
 * Converts a Date into a TimeSpan representing it's time of day
 */
export const dateToTimeSpan = (date: Date) => format(date, "HH:mm:ss") as TimeSpan;

/**
 * Compares two time spans. Returns a number which can be used in sort methods
 * @example compareTimeSpanAsc("02:00:00", "05:00:00") = -1
 * @example compareTimeSpanAsc("02:00:00", "02:00:00") = 0
 * @example compareTimeSpanAsc("02:00:00", "01:00:00") = -1
 */
export const compareTimeSpanAsc = (a: TimeSpan, b: TimeSpan) => {
    const [aHours, aMinutes, aSeconds] = a.split(":").map((x) => Number(x));
    const [bHours, bMinutes, bSeconds] = b.split(":").map((x) => Number(x));
    return aHours < bHours ? -1 : aHours === bHours ? (aMinutes < bMinutes ? -1 : aMinutes === bMinutes ? (aSeconds < bSeconds ? -1 : aSeconds === bSeconds ? 0 : 1) : 1) : 1;
};

/**
 * Given a target start/end timespans and a bounds start/end timespans, Returns true if the target is within the bounds.
 * Takes into consideration and end timespan being smaller than start bounds to support end times after midnight.
 */
export const withinBounds = (target: [TimeSpan, TimeSpan], bounds: [TimeSpan, TimeSpan]) => {
    const endsAfterMidnight = compareTimeSpanAsc(bounds[0], bounds[1]) > -1;
    if (endsAfterMidnight) {
        const targetStartsWithin = compareTimeSpanAsc(target[0], bounds[1]) === -1 || compareTimeSpanAsc(target[0], bounds[0]) > -1;
        const targetEndsWithin = compareTimeSpanAsc(target[1], bounds[1]) < 1 || compareTimeSpanAsc(target[1], bounds[0]) === 1;
        return targetStartsWithin && targetEndsWithin;
    }
    return (
        // Target starts after bounds start
        compareTimeSpanAsc(target[0], bounds[0]) > -1 &&
        // Target starts before bounds start
        compareTimeSpanAsc(target[0], bounds[1]) === -1 &&
        // Target ends before bounds end
        compareTimeSpanAsc(target[1], bounds[1]) < 1 &&
        // Target ends after bounds start
        compareTimeSpanAsc(target[1], bounds[0]) === 1
    );
};

export const convertTimeStringToNum = (time: string) => (time != null ? time.split(":").map((x) => Number(x)) : null);

// When a user creates a new event, we are assuming the time provided is in the timezone of the target venue, but the date is UTC
// Therefore we have to convert the UTC date to the intended timezone, set the hours, minutes and seconds and then convert it back to UTC
export const setTimeOnEventDay = (date: Date, time: string, timezone: string) => {
    // Determine the aspects of the time
    const timeSplit = convertTimeStringToNum(time);
    const hours = timeSplit[0];
    const minutes = timeSplit[1];
    const seconds = timeSplit[2];

    // Get the day number in its intended timezone
    let dayInTimezone = utcToZonedTime(date, timezone);

    // Assign the hours, minutes and seconds to the date only part of this
    dayInTimezone = new Date(dayInTimezone.getFullYear(), dayInTimezone.getMonth(), dayInTimezone.getDate(), hours, minutes, seconds);

    // Convert backt to UTC ready for the API
    const utc = zonedTimeToUtc(dayInTimezone, timezone);

    return utc;
};

export const formatDateInTimezone = (date: Date, timezone) => utcToZonedTime(date, timezone);

export const formatTimeSpanInWords = (timeSpan: TimeSpan) => formatDuration(timeSpanToDuration(timeSpan), { format: ["hours", "minutes"] });

export const formatTimeSpanInWordsShort = (timeSpan: TimeSpan) =>
    formatTimeSpanInWords(timeSpan)
        ?.replace("hours", "hrs")
        .replace("hour", "hr")
        .replace("minutes", "mins")
        .replace("minute", "min")
        .replace("seconds", "secs")
        .replace("second", "sec");
