/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { Layer } from "mapbox-gl";

type StylePropertyToScale = "line-width" | "text-size" | "icon-size";

export type SkipModifiers = Map<StylePropertyToScale, string[]>;

/** Modifies lines and symbols to show better in exports */
export function layerScaleModifier(mapLayers: Layer[], scale: number, layerIdsToSkipTextModifier: string[] = []) {
    mapLayers.forEach((layer) => {
        // For lines increase their width
        if (layer.type === "line") {
            scaleModifier(layer, scale, layer.paint["line-width"], "line-width");
        }

        // For symbols increase their font size
        if (layer.type === "symbol" && !layerIdsToSkipTextModifier.includes(layer.id)) {
            scaleModifier(layer, scale, layer.layout["text-size"], "text-size");
        }

        // For symbols increase their icon size
        if (layer.type === "symbol" && !layerIdsToSkipTextModifier.includes(layer.id)) {
            scaleModifier(layer, scale, layer.layout["icon-size"], "icon-size");
        }
    });
}

/** Selects correct function for layer style property value */
function scaleModifier(layer: Layer, scale: number, value: number | object | any[], propertyChanged: StylePropertyToScale) {
    if (typeof value === "number") {
        numberValueScaleModifier(layer, scale, value, propertyChanged);
    } else if (Array.isArray(value)) {
        scaleArrayValue(value, scale);
    } else if (typeof value === "object") {
        scaleObjectValue(value, scale);
    }
}

function numberValueScaleModifier(layer: Layer, scale: number, value: number, propertyChanged: StylePropertyToScale) {
    switch (propertyChanged) {
        case "line-width":
            layer.paint["line-width"] = value * scale;
            break;
        case "text-size":
            layer.layout["text-size"] = value * scale;
            break;
        case "icon-size":
            layer.layout["icon-size"] = value * scale;
            break;
        default:
            throw new Error(`${propertyChanged} is not handled`);
    }
}

/** Scales a style property which is an array */
function scaleArrayValue(value: any[], scale: number) {
    value.forEach((nestedValue, index) => {
        if (typeof nestedValue === "number" && index % 2 === 0) {
            value[index] = nestedValue * scale;
        }
    });
}

/** Scales a style property value which is an object */
function scaleObjectValue(value: any, scale: number) {
    value.stops.forEach((stop) => {
        stop[1] *= scale;
    });
}
