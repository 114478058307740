import { createMachine } from "xstate";
import { CommentsMachineEvent } from "./comments-drawing-machine-types";

export const commentsDrawingMachineMobile = createMachine({
    context: {},
    id: "Comments machine (mobile)",
    initial: "initial",
    on: {
        EXIT: "destroyed",
        SELECT_COMMENT: { target: "edit", actions: "selectComment" },
        DELETE_COMMENT: { target: "edit", actions: "deleteComment" },
    },
    states: {
        initial: {
            always: [
                {
                    target: "edit",
                    cond: "existingObject",
                },
                {
                    target: "composition",
                },
            ],
        },
        edit: {
            on: {
                CLICK: [{ cond: "isCommentSelected", target: "edit" }, { actions: "selectComment" }],
                PAN_START: [{ cond: "canEditComment", target: "panning" }, { actions: "deselectComment" }],
            },
        },
        composition: {
            exit: "compositionHasEnded",
            on: {
                CLICK: { target: "edit", actions: "selectComment" },
                CREATE: { target: "edit", actions: "createComment" },
            },
        },
        awaitingConfirmation: {
            on: {
                CONFIRM_COMMENT_MOVE: { target: "edit", actions: "panningHasEnded" },
                CANCEL: "edit",
            },
        },
        panning: {
            on: {
                PAN_END: "awaitingConfirmation",
            },
            entry: "panningHasStarted",
        },
        destroyed: {
            type: "final",
        },
    },
    schema: {
        events: {} as CommentsMachineEvent,
    },
    // eslint-disable-next-line no-undef
    tsTypes: {} as import("./comments-drawing-mobile-machine.typegen").Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
});
