export interface RestrictedCdnSignature {
    url: string;
    signature: string;
    expiry: string;
    assetVersion: Date;
}

const findSignedUrl = (url: string, signatures: RestrictedCdnSignature[]): RestrictedCdnSignature => {
    const signedUrl = signatures.find((s) => url.toLowerCase().startsWith(s.url.toLowerCase()));
    return signedUrl;
};

export const getSignedUrl = (url: string, signatures: RestrictedCdnSignature[]): string => {
    const signedUrl = findSignedUrl(url.toLowerCase(), signatures);
    if (signedUrl == null) {
        throw new Error(`Attempted to get a signed URL which does not exist. ${url}`);
    }
    const expiry = new Date(signedUrl.expiry);
    if (expiry < new Date()) {
        throw new Error(`Attempted to get a signed URL which has expired. ${url}`);
    }
    return `${url}?${signedUrl.signature}&assetVersion=${signedUrl.assetVersion}`;
};

export const addSignedUrl = (signature: RestrictedCdnSignature, signatures: RestrictedCdnSignature[]): void => {
    const existingSignature = signatures.find((s) => s.url === signature.url.toLowerCase());
    signatures.splice(signatures.indexOf(existingSignature), 1);
    signatures.push(signature);
};
