/* eslint-disable no-spaced-func */
/* eslint-disable func-call-spacing */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import { LayerIconGenerated } from "@iventis/map-object-thumbnails";
import { styled } from "@iventis/styles";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { useQuery } from "@tanstack/react-query";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { getMostProminentStyleValue, styleTypeToLayerProperties } from "@iventis/map-engine/src/utilities/style-helpers";
import { assetUrlGetter, getAsset, getAttributeListItems, multipleModelsGetter } from "./api/routes";

/** Takes a layer and returns a promise of all the list items tied to its' data fields */
const getFlatListItems = async (layer: MapLayer) => {
    const layerStyle = layer[styleTypeToLayerProperties[layer.styleType]];
    const styleValue = getMostProminentStyleValue(layer);
    if (styleValue.extractionMethod !== StyleValueExtractionMethod.Mapped || styleValue.dataFieldId == null || Object.keys(styleValue.mappedValues ?? {})?.length === 0) {
        return [];
    }
    const listItems = (await getAttributeListItems(styleValue.dataFieldId))?.filter((li) => Object.keys(styleValue.mappedValues).includes(li.id));

    return listItems.map((item) => ({ ...item, layerStyle }));
};

/** Gets all the list items for the given layers' data fields and renders the thumbnail outside the viewport (with id = list item id) */
export const AttributeThumbnailGenerator = ({ layers, onThumbnailsLoaded }: { layers: MapLayer[]; onThumbnailsLoaded: () => void }) => {
    const { data: allListItems, isSuccess } = useQuery({
        queryKey: ["list-items"],
        queryFn: async () => {
            const flatListItems = await Promise.all(layers.map(getFlatListItems));
            // Return all unique list items
            return flatListItems.reduce((acc, items) => [...acc, ...items.filter((item) => !acc.some(({ id }) => item.id === id))], []);
        },
        enabled: layers?.length > 0,
    });
    const [thumbnailsLoaded, setThumbnailsLoaded] = useState(0);

    useEffect(() => {
        if (isSuccess && allListItems?.length <= thumbnailsLoaded) {
            onThumbnailsLoaded();
        }
    }, [thumbnailsLoaded, isSuccess, allListItems]);
    return (
        <StyledThumbnailContainer>
            {allListItems?.map((listItem) => (
                <LayerIconGenerated
                    style={listItem.layerStyle}
                    styleSelector={listItem.id}
                    previewIconUrlGetter={assetUrlGetter}
                    getAsset={getAsset}
                    getModelThumbnail={async (modelId) => {
                        const [model] = await multipleModelsGetter([modelId]);
                        return assetUrlGetter(model.thumbnailAssetId);
                    }}
                    onRendered={() => setThumbnailsLoaded((prev) => prev + 1)}
                />
            ))}
        </StyledThumbnailContainer>
    );
};

const StyledThumbnailContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    margin-left: -20px;
    height: 100vh;
    overflow-y: hidden;
`;
