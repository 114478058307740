import { FixedShape } from "@iventis/domain-model/model/fixedShape";
import { featureCollection, feature } from "@turf/helpers";
import GeoJSON from "geojson";
import { midpointHandleLayerId } from "../bridge/constants";
import { Listener, MoveEvent } from "../types/internal";
import { getMidpointsRhumb } from "./geojson-helpers";

export type Midpoint = GeoJSON.Feature<GeoJSON.Point, MidpointProperties>;

export enum MidpointHandleEvent {
    CLICK = "CLICK",
    DRAG = "DRAG",
    ENTER = "ENTER",
    LEAVE = "LEAVE",
}

interface ListenerPattern {
    [MidpointHandleEvent.CLICK]: ((point: Midpoint) => void)[];
    [MidpointHandleEvent.DRAG]: ((point: Midpoint) => void)[];
    [MidpointHandleEvent.ENTER]: ((point: GeoJSON.Point) => void)[];
    [MidpointHandleEvent.LEAVE]: (() => void)[];
}

export interface MidpointProperties {
    indexBefore: number;
    fixedShape?: FixedShape;
}

export interface Dragging {
    isDragging: boolean;
}

export class MidpointHandles {
    private listeners: ListenerPattern = {
        [MidpointHandleEvent.CLICK]: [],
        [MidpointHandleEvent.ENTER]: [],
        [MidpointHandleEvent.LEAVE]: [],
        [MidpointHandleEvent.DRAG]: [],
    };

    private geometry: GeoJSON.FeatureCollection<GeoJSON.Point>;

    private mouseDownHandleListener: Listener;

    private enteredHandle: boolean;

    private mouseMoveListener: Listener;

    private onContentUnderMouseChangedListener: Listener;

    constructor(
        coordinates: GeoJSON.Position[],
        public operations: {
            onMouseMove: (callback: (e: MoveEvent) => void, radius?: number) => Listener;
            onMouseDownHandle: (callback: (point: Midpoint) => void) => Listener;
            onContentUnderMouseChanged: (callback: (e: MoveEvent) => void) => Listener;
            setHandleGeometry: (geometry: GeoJSON.FeatureCollection<GeoJSON.Point>) => void;
        }
    ) {
        this.geometry = getCoordinateGeometry(coordinates);
        this.operations.setHandleGeometry(this.geometry);

        const contentUnderMouseChanged = (event) => {
            const midpointHandleObject = event.objects.find((object) => object.properties.layerid === midpointHandleLayerId);

            if (midpointHandleObject && !this.enteredHandle) {
                this.enteredHandle = true;
                this.listeners.ENTER.forEach((listener) => listener(((midpointHandleObject as unknown) as GeoJSON.Feature<GeoJSON.Point>).geometry));
            } else if (this.enteredHandle && !event.objects.some((object) => object.properties.layerid === midpointHandleLayerId)) {
                this.enteredHandle = false;
                this.listeners.LEAVE.forEach((listener) => listener());
            }
        };

        this.mouseMoveListener = this.operations.onMouseMove(contentUnderMouseChanged);

        this.onContentUnderMouseChangedListener = this.operations.onContentUnderMouseChanged(contentUnderMouseChanged);

        this.mouseDownHandleListener = this.operations.onMouseDownHandle((point) => {
            const moveAfterDown = this.operations.onMouseMove(() => {
                this.listeners[MidpointHandleEvent.DRAG].forEach((listener) => listener(point));
                moveAfterDown.remove();
                document.removeEventListener("mouseup", mouseUpFunction);
            });

            const mouseUpFunction = () => {
                this.listeners[MidpointHandleEvent.CLICK].forEach((listener) => listener(point));
                moveAfterDown.remove();
                document.removeEventListener("mouseup", mouseUpFunction);
            };

            document.addEventListener("mouseup", mouseUpFunction);
        });
    }

    removeHandles() {
        this.operations.setHandleGeometry({
            type: "FeatureCollection",
            features: [],
        });
        this.mouseDownHandleListener.remove();
    }

    destroy() {
        this.operations.setHandleGeometry({
            type: "FeatureCollection",
            features: [],
        });

        this.mouseDownHandleListener?.remove();
        this.mouseMoveListener.remove();
        this.onContentUnderMouseChangedListener.remove();
    }

    public on<E extends MidpointHandleEvent>(event: E, callback: ListenerPattern[keyof ListenerPattern][0]) {
        this.listeners[event].push(callback as () => void);
        return this;
    }
}

export function getCoordinateGeometry(coordinates: GeoJSON.Position[]): GeoJSON.FeatureCollection<GeoJSON.Point> {
    if (coordinates === undefined || coordinates.length === 0) {
        return {
            type: "FeatureCollection",
            features: [],
        };
    }

    const midpoints = getMidpointsRhumb(coordinates);

    // Add the midpoint and the current index
    // If clicked, the midpoint will be added
    // at indexBefore + 1
    const midpointFeatures = midpoints.map(
        (point, index) =>
            feature(point, {
                indexBefore: index,
            }) as Midpoint
    );

    return featureCollection(midpointFeatures);
}
