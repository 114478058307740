/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Layer } from "mapbox-gl";
import { getMapboxFilterType } from "./engine-mapbox-filter-type-helpers";
import { FilterType } from "./engine-mapbox-filter-types";

/** Updates or adds a filter to an existing layer filter */
export function updateLayerFilters(existingLayerFilter: Layer["filter"], filterToAddOrUpdate: Layer["filter"]): Layer["filter"] {
    // If there is no filter, create a new one
    if (existingLayerFilter == null) {
        return ["all", filterToAddOrUpdate];
    }

    const filterType = getMapboxFilterType(filterToAddOrUpdate);

    // Check the decision expression is the first element
    if (existingLayerFilter[0] === "all") {
        const [decision, ...filters] = existingLayerFilter;
        // If the filter type is the same to an existing one, remove it
        const filteredFilter = filters.filter((f) => getMapboxFilterType(f) !== filterType);
        // Add the new filter
        return [decision, ...filteredFilter, filterToAddOrUpdate];
    }

    // If the new filter type is the same to an existing one, update with the new filter
    if (getMapboxFilterType(existingLayerFilter) === filterType) {
        return ["all", filterToAddOrUpdate];
    }

    // If the existing filter type is different, add the new filter
    return ["all", existingLayerFilter, filterToAddOrUpdate];
}

/** Returns a new filter, with the given filter type removed from the existing layer filter  */
export function removeLayerFilterByType(existingLayerFilter: Layer["filter"], filterTypeToRemove: FilterType): Layer["filter"] {
    // If there is no filter, return null
    if (existingLayerFilter == null) {
        return null;
    }

    // Check the decision expression is the first element
    if (existingLayerFilter[0] === "all") {
        const [decision, ...filters] = existingLayerFilter;
        // Remove the filter which matches the given type
        const filteredFilter = filters.filter((f) => getMapboxFilterType(f) !== filterTypeToRemove);
        // Create the new filter
        return [decision, ...filteredFilter];
    }

    // If the filter type is the same to the given one, return an empty array
    if (getMapboxFilterType(existingLayerFilter) === filterTypeToRemove) {
        return [];
    }

    // Else given type could not be found in the filter so return the existing filter
    return existingLayerFilter;
}

export function getLayerFilterByType(existingFilter: Layer["filter"], filterTypeToGet: FilterType): Layer["filter"] {
    // If there is no filter, return null
    if (existingFilter == null) {
        return null;
    }

    if (existingFilter[0] === "all") {
        const [_, ...filters] = existingFilter;
        // Return the filter which matches the given type
        return filters.find((f) => getMapboxFilterType(f) === filterTypeToGet);
    }

    if (getMapboxFilterType(existingFilter) === filterTypeToGet) {
        return existingFilter;
    }

    return null;
}
