import { createMapFromArray } from "@iventis/utilities";
import { MapModuleLayer } from "../../../types/store-schema";
import { getLayerMapOrderValue } from "./layer-ordering-generic-helpers";
import { LayerMovementAction } from "./layer-ordering-types-and-constants";

/** Finds the layer which has been moved to either the top or bottom of the map */
export function findLayerWithOrderingChange(currentLayerState: MapModuleLayer[], previousLayerState: MapModuleLayer[]): { layer: MapModuleLayer; action: LayerMovementAction } {
    const filteredCurrentLayerState = currentLayerState.filter((layer) => layer.remote);
    const filteredPreviousLayerState = previousLayerState.filter((layer) => layer.remote);

    const previousLayerStateMap = createMapFromArray(filteredPreviousLayerState, "id");

    // Find the layer with the lowest map order value
    const lowestMapLayer = filteredCurrentLayerState.reduce((lowestOrderLayer, layer) => {
        if (lowestOrderLayer == null || getLayerMapOrderValue(layer) < getLayerMapOrderValue(lowestOrderLayer)) {
            return layer;
        }
        return lowestOrderLayer;
    }, null);

    const previousStateLowestLayer = previousLayerStateMap.get(lowestMapLayer?.id);

    // Check if lowest map order value layer has changed map order
    if (previousStateLowestLayer != null && getLayerMapOrderValue(previousStateLowestLayer) !== getLayerMapOrderValue(lowestMapLayer)) {
        return { layer: lowestMapLayer, action: LayerMovementAction.MoveToBottom };
    }

    // Find the layer with the highest map order value
    const highestMapLayer = filteredCurrentLayerState.reduce((highestOrderLayer, layer) => {
        if (highestOrderLayer == null || getLayerMapOrderValue(layer) > getLayerMapOrderValue(highestOrderLayer)) {
            return layer;
        }
        return highestOrderLayer;
    }, null);

    const previousStateHighestLayer = previousLayerStateMap.get(highestMapLayer?.id);

    // Check if highest map order value layer has changed map order
    if (previousStateHighestLayer != null && getLayerMapOrderValue(previousStateHighestLayer) !== getLayerMapOrderValue(highestMapLayer)) {
        return { layer: highestMapLayer, action: LayerMovementAction.MoveToTop };
    }

    return null;
}
