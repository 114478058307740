type AvailableTypes = string | number | symbol;
type ValidObject = { [items in AvailableTypes]: AvailableTypes };

/**
 * Gets the key of value inside of given object
 * @param object
 * @param value
 * @returns the key if it exists, otherwise returns null;
 */
export const getKeyIn = (object: ValidObject, value: AvailableTypes): AvailableTypes | null => Object.entries(object).find((pair) => pair[1] === value)?.[0] ?? null;
