import { FixedShape } from "@iventis/domain-model/model/fixedShape";
import GeoJSON from "geojson";
import { Listener, FixedShapeSupportedGeometry } from "../types/internal";
import { MapObjectProperties } from "../types/store-schema";
import { resizeRectangle } from "./geojson-helpers";

export enum FixedShapeEvent {
    MOVE = "MOVE",
    START = "START",
    FINISH = "FINISH",
}

interface ListenerPattern {
    [FixedShapeEvent.MOVE]: ((feature: GeoJSON.Feature, moveEvent: { lng: number; lat: number }) => void)[];
    [FixedShapeEvent.START]: ((feature: GeoJSON.Feature) => void)[];
    [FixedShapeEvent.FINISH]: ((feature?: GeoJSON.Feature) => void)[];
}

export class CompositionFixedShape {
    private listeners: ListenerPattern = {
        [FixedShapeEvent.MOVE]: [],
        [FixedShapeEvent.START]: [],
        [FixedShapeEvent.FINISH]: [],
    };

    private mouseMoveListener: Listener;

    private mouseClickListener: Listener;

    private mapRotateListener: Listener;

    private destroyed: boolean;

    private coordLastClick: { lng: number; lat: number } = { lng: NaN, lat: NaN };

    private coordLastHovered: { lng: number; lat: number } = { lng: NaN, lat: NaN };

    private idle = true;

    private mapBearing: number;

    private enterKeyListener: Listener;

    private rightClickListener: Listener;

    constructor(
        private object: GeoJSON.Feature<FixedShapeSupportedGeometry, MapObjectProperties>,
        private operations: {
            onEnterKeyPress(callback: (e: KeyboardEvent) => void): Listener;
            onMouseMove: (callback: (e: { lng: number; lat: number }) => void, radius?: number) => Listener;
            onClick: (callback: (e: { lng: number; lat: number }) => void) => Listener;
            onRightClick: (callback: (e: { lng: number; lat: number }) => void) => Listener;
            onRotateEnd: (callback: (bearing: number) => void) => Listener;
        },
        getBearing: () => Promise<number>
    ) {
        if (this.object.geometry.coordinates.length > 0) {
            this.idle = false;
        }
        (async () => {
            this.mapBearing = await getBearing();
        })();

        this.mapRotateListener = this.operations.onRotateEnd((bearing) => {
            this.mapBearing = bearing;
        });

        this.mouseMoveListener = this.operations.onMouseMove((position) => {
            if (this.destroyed || this.idle) {
                // These events might come in after the class is destroyed. Do nothing if they do.
                return;
            }
            this.coordLastHovered = { lat: position.lat, lng: position.lng };
            this.listeners.MOVE.forEach((listener) => listener(this.getTransformation(position.lng, position.lat), position));
        });
        this.mouseClickListener = this.operations.onClick((position) => {
            if (this.destroyed) {
                return;
            }

            if (this.idle) {
                this.listeners.START.forEach((listener) => listener(this.getTransformation(position.lng, position.lat)));
                this.coordLastClick = position;
                this.idle = false;
                return;
            }
            // If second click is in the same place, do not finish
            if (this.coordLastClick.lng === position.lng && this.coordLastClick.lat === position.lat) {
                return;
            }
            this.listeners.FINISH.forEach((listener) => listener(this.getTransformation(position.lng, position.lat)));
        });

        this.enterKeyListener = this.operations.onEnterKeyPress((event) => {
            if (this.destroyed) {
                return;
            }
            if (event.key !== "Enter") return;

            // If last click is in the same place as currently hovering, do not perform append
            if (this.coordLastClick.lng === this.coordLastHovered.lng && this.coordLastClick.lat === this.coordLastHovered.lat) {
                this.listeners.FINISH.forEach((listener) => listener());
                return;
            }
            // otherwise update last coord, append a point and finish up
            this.coordLastClick = this.coordLastHovered;
            this.listeners.FINISH.forEach((listener) => listener(this.getTransformation(this.coordLastHovered.lng, this.coordLastHovered.lat)));
        });

        this.rightClickListener = this.operations.onRightClick((position) => {
            if (this.destroyed) {
                return;
            }

            if (this.idle) {
                this.listeners.START.forEach((listener) => listener(this.getTransformation(position.lng, position.lat)));
                this.coordLastClick = position;
                this.idle = false;
                return;
            }
            // If second click is in the same place, do not finish
            if (this.coordLastClick.lng === position.lng && this.coordLastClick.lat === position.lat) {
                return;
            }
            this.listeners.FINISH.forEach((listener) => listener(this.getTransformation(position.lng, position.lat)));
        });
    }

    setObject(object: GeoJSON.Feature<FixedShapeSupportedGeometry, MapObjectProperties>) {
        this.object = object;
    }

    getTransformation(lng: number, lat: number) {
        const transformedFeature: GeoJSON.Feature<FixedShapeSupportedGeometry, MapObjectProperties> = {
            ...this.object,
            geometry: resizeShape[this.object.properties.fixedShape](this.object.geometry, [lng, lat], this.mapBearing),
        };
        return transformedFeature;
    }

    public on<E extends FixedShapeEvent>(event: E, callback: ListenerPattern[keyof ListenerPattern][0]) {
        this.listeners[event].push(callback as () => void);
        return this;
    }

    public destroy() {
        this.mouseMoveListener.remove();
        this.mouseClickListener.remove();
        this.mapRotateListener.remove();
        this.enterKeyListener.remove();
        this.rightClickListener.remove();
        this.idle = true;
        this.destroyed = true;
    }
}

/** Object with keys as the fixed shape type and values as the functions returning the new geometry */
const resizeShape: Record<FixedShape, (currentGeom: FixedShapeSupportedGeometry, newCoords: GeoJSON.Position, bearing: number) => FixedShapeSupportedGeometry> = {
    [FixedShape.Rectangle]: resizeRectangle,
};
