/* eslint-disable no-console */
import { Layer } from "mapbox-gl";
import { FilterType } from "./engine-mapbox-filter-types";

/** Checks what sort of filter, if filter is not handled then null is return */
export function getMapboxFilterType(filter: Layer["filter"]): FilterType {
    switch (true) {
        case isDateFilter(filter):
            return FilterType.Date;
        case isLevelFilter(filter):
            return FilterType.Level;
        case isBaseLayerFilter(filter):
            return FilterType.LayerBase;
        case isMapObjectFilter(filter):
            return FilterType.MapObject;
        case isLineModelGuidLineFilter(filter):
            return FilterType.LineModelGuideLine;
        default:
            console.warn(`Unknown filter type ${filter}`);
            return null;
    }
}

/** Checks if the filter is used for sitemap levels
 *
 * Example: ["==", ["get", "level"], 1]
 */
export function isLevelFilter(filter: Layer["filter"]): boolean {
    if (filter == null) {
        return false;
    }

    return filter[0] === "==" && filter[1]?.[0] === "get" && filter[1]?.[1] === "level";
}

/** Checks if the filter is used for date filtering */
export function isDateFilter(filter: Layer["filter"]): boolean {
    if (filter == null) {
        return false;
    }

    return filter[0] === "any" && filter[1]?.[0] === "all" && filter[1]?.[1]?.[0] === "!" && filter[1]?.[1]?.[1]?.[1]?.includes("start-date");
}

/**
 * Checks if the filter is a base, only used to integrate other filters together
 *
 * Example: ["all", [ ["==", ["get", "level"], 1] ]
 */
export function isBaseLayerFilter(filter: Layer["filter"]): boolean {
    if (filter == null) {
        return false;
    }

    const [decision, ...filters] = filter;
    return decision === "all" && filters.length !== 0 && filters.every((f) => Array.isArray(f));
}

/**
 * Checks if the filter is used to filter out remote map objects
 *
 * Example: ["!=", ["get", "id"], "8f3b7d11-5b1c-4d34-b30b-bc841b45e070"]
 */
export function isMapObjectFilter(filter: Layer["filter"]): boolean {
    if (filter == null) {
        return false;
    }

    return filter[0] === "!=" && filter[1]?.[0] === "get" && filter[1]?.[1] === "id";
}

/**
 * Checks if the filter is used to filter out remote map objects
 *
 * Example: ["in", "id", "8f3b7d11-5b1c-4d34-b30b-bc841b45e070"]
 */
export function isLineModelGuidLineFilter(filter: Layer["filter"]): boolean {
    if (filter == null) {
        return false;
    }

    return filter[0] === "in" && filter[1] === "id";
}
