import { FeatureCollection } from "@turf/helpers";
import { Point } from "geojson";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { MapObjectProperties } from "../../../../types/store-schema";
import { MapObject3D } from "../engine-3d-types";
import { MapObject3DScale, MapObject3DScaleAttribute } from "../engine-3d-scale-types";
import { defaultScale } from "../3d-engine-scale-helpers";
import { ModelLayerStyle } from "../../../../types/models";
import { getStaticStyleValueFromMapped } from "../../../../utilities/static-styles";

export const DEFAULT_LIST_ITEM_ID = "default";

export const parseGeoJsonToMapObject3D = (
    geoJson: FeatureCollection<Point, MapObjectProperties>,
    layerId: string,
    layerScaleValue: MapObject3DScale,
    currentLevel: number,
    listItemIds: string[],
    layerName: string,
    datesFilter: boolean,
    modelAttributeId?: string
): { [layerId: string]: MapObject3D[] } => {
    const startingValue = { default: [] };
    listItemIds.forEach((listItemId) => {
        startingValue[listItemId] = [];
    });

    return geoJson.features.reduce((features, feature) => {
        if (currentLevel === feature.properties.level && (!datesFilter || !feature.properties.dateFiltered)) {
            const key = (feature.properties?.[modelAttributeId] as string) ?? DEFAULT_LIST_ITEM_ID;
            (features[key] ?? features[DEFAULT_LIST_ITEM_ID]).push({
                id: feature.properties.id,
                layerId,
                layerName,
                position: [feature.geometry.coordinates[0], feature.geometry.coordinates[1], 0],
                rotation: feature.properties.rotation,
                properties: feature.properties,
                scale: layerScaleValue?.type === "attribute" ? getAttributeBasedScaleValue(layerScaleValue, feature.properties) : layerScaleValue?.value ?? defaultScale.value,
            });
        }

        return features;
    }, startingValue);
};

export const getAttributeBasedScaleValue = (layerScaleValue: MapObject3DScaleAttribute, properties: MapObjectProperties) => {
    const scaleValue = layerScaleValue.value[(properties[layerScaleValue.dataFieldId] as string) ?? DEFAULT_LIST_ITEM_ID];
    return scaleValue ?? layerScaleValue.value[DEFAULT_LIST_ITEM_ID];
};

export const isModelAttributeBased = (model: ModelLayerStyle["model"]) => model?.extractionMethod === StyleValueExtractionMethod.Mapped;

export const getModelAndListItemId = (style: ModelLayerStyle) => {
    const mappedValues = Object.entries(style.model.mappedValues ?? {}).map(([listItemId, { staticValue }]) => ({ listItemId, modelId: staticValue }));
    return [...mappedValues, { listItemId: DEFAULT_LIST_ITEM_ID, modelId: getStaticStyleValueFromMapped(style.model) }];
};
