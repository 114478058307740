import { createMachine } from "xstate";
import { CommentsMachineContext, CommentsMachineEvent } from "./comments-drawing-machine-types";

export const commentsDrawingMachineDesktop = createMachine(
    {
        context: {},
        id: "Comments machine",
        initial: "initial",
        on: {
            EXIT: "#Comments machine.destroyed",
            CONTENT_UNDER_MOUSE_CHANGED: { actions: "contentUnderMouseChanged" },
            MOUSE_MOVE: { actions: "contentUnderMouseChanged" },
            CANCEL: { target: "default", actions: "deselectComment" },
            HOVER_OVER_COMMENT: [
                {
                    cond: "isCommentSelected",
                    target: "overSelectedComment",
                },
                {
                    target: "overNonSelectedComment",
                },
            ],
            SELECT_COMMENT: { target: "default", actions: "selectComment" },
            DELETE_COMMENT: { target: "default", actions: "deleteComment" },
            PAN_START: "panning",
        },
        states: {
            initial: {
                always: [
                    {
                        target: "default",
                        cond: "isACommentSelected",
                    },
                    {
                        target: "composition",
                    },
                ],
            },
            default: {
                on: {
                    CLICK: [
                        {
                            cond: "isACommentSelected",
                            actions: "deselectComment",
                        },
                    ],
                },
            },
            composition: {
                exit: "compositionHasEnded",
                on: {
                    CLICK: [
                        {
                            cond: "isOverComment",
                            target: "overSelectedComment",
                            actions: "selectComment",
                        },
                        {
                            target: "overSelectedComment",
                            actions: "createComment",
                        },
                    ],
                    // Block hover event as it's handled on click
                    HOVER_OVER_COMMENT: {},
                    // Block pan start event because we don't need to handle panning while in composition
                    PAN_START: {},
                },
            },
            overSelectedComment: {
                on: {
                    DRAG_START: { cond: "canEditComment", target: "dragging" },
                    MOVE_AWAY: "default",
                },
            },
            overNonSelectedComment: {
                on: {
                    CLICK: {
                        target: "overSelectedComment",
                        actions: "selectComment",
                    },
                    MOVE_AWAY: "default",
                },
            },
            dragging: {
                on: {
                    MOUSE_MOVE: {},
                    DRAG_END: "overSelectedComment",
                    HOVER_OVER_COMMENT: {},
                },
                entry: "draggingHasStarted",
                exit: "draggingHasEnded",
            },
            panning: {
                on: {
                    PAN_END: { target: "default", actions: "panningHasEnded" },
                },
            },
            destroyed: {
                type: "final",
            },
        },
        schema: { events: {} as CommentsMachineEvent, context: {} as CommentsMachineContext },
        // eslint-disable-next-line no-undef
        tsTypes: {} as import("./comments-drawing-desktop-machine.typegen").Typegen0,
        predictableActionArguments: true,
        preserveActionOrder: true,
    },
    {
        guards: {},
        actions: {},
        services: {},
        delays: {},
    }
);
