/* eslint-disable class-methods-use-this */
/* eslint-disable no-async-promise-executor */
export class CommentIcon {
    private readonly height = 44;

    private readonly width = 44;

    private readonly padding = 8;

    private readonly shadowBlur = 4;

    private readonly shadowOffset = 2;

    private readonly shadowColour = "rgba(0, 0, 0, 0.5)";

    private readonly imageHeight: number;

    private readonly imageWidth: number;

    private readonly backgroundColour = "#FFFFFF";

    private readonly userProfileImageUrl: string;

    constructor(userProfileImageUrl: string) {
        this.userProfileImageUrl = userProfileImageUrl;
        this.imageHeight = this.height - this.padding;
        this.imageWidth = this.width - this.padding;
    }

    /** Returns a HTML Image element which is the comment icon for a given user */
    public async createIcon(): Promise<HTMLImageElement> {
        return new Promise(async (resolve) => {
            const userProfileImage = await this.getUserProfileImage(this.userProfileImageUrl);
            const commentImage = await this.createCommentImage(userProfileImage);
            resolve(commentImage);
        });
    }

    /** Gets user profile image and adds it to a canvas */
    private async getUserProfileImage(url: string): Promise<string> {
        return new Promise((resolve) => {
            // Create canvas to place the image on
            const canvas = this.createCanvas();
            const userImage = new Image();
            userImage.src = url;
            userImage.crossOrigin = "*";
            // When image has been loaded, add it on the canvas
            userImage.onload = () => {
                userImage.height = this.imageHeight;
                userImage.width = this.imageWidth;
                const context = canvas.getContext("2d");
                context.shadowColor = "transparent";
                context.drawImage(userImage, this.padding / 2, this.padding / 2, this.imageWidth, this.imageHeight);
                resolve(canvas.toDataURL());
            };
        });
    }

    /** Create a canvas with basic styling */
    private createCanvas(): HTMLCanvasElement {
        const canvas = document.createElement("canvas");
        canvas.height = this.height + this.shadowBlur;
        canvas.width = this.width + this.shadowBlur;

        const circleCenterX = this.width * 0.5;
        const circleCenterY = this.height * 0.5;
        const circleRadius = this.width * 0.5;

        const context = canvas.getContext("2d");

        // Draw half a circle which will be used for the shadow
        context.beginPath();
        context.arc(circleCenterX, circleCenterY, circleRadius, 1.5 * Math.PI, 0.5 * Math.PI, false); // Draw right half of the circle
        context.shadowColor = this.shadowColour;
        context.fillStyle = this.backgroundColour;
        context.shadowOffsetY = this.shadowOffset;
        context.shadowOffsetX = this.shadowOffset;
        context.shadowBlur = this.shadowBlur;
        context.fill();

        // Draw square to create the comment point
        context.beginPath();
        context.shadowColor = this.shadowColour;
        context.shadowOffsetY = this.shadowOffset;
        context.shadowOffsetX = this.shadowOffset;
        context.shadowBlur = this.shadowBlur;
        context.fillStyle = this.backgroundColour;
        context.fillRect(0, this.height / 2, this.width / 2, this.height / 2);
        context.fill();

        // Draw circle for user image background
        context.beginPath();
        context.arc(circleCenterX, circleCenterY, circleRadius, 0, 2 * Math.PI, false);
        context.fillStyle = this.backgroundColour;
        // Already added shadow so set to transparent
        context.shadowColor = "transparent";
        context.fill();

        return canvas;
    }

    /** Converts the canvas to an image element */
    private createCommentImage(baseCanvasUrl: string): Promise<HTMLImageElement> {
        return new Promise((resolve) => {
            const canvasImage = new Image();
            canvasImage.src = baseCanvasUrl;
            canvasImage.onload = () => {
                resolve(canvasImage);
            };
        });
    }
}
