import { Authorisation } from "@iventis/domain-model/model/authorisation";
import { Node } from "@iventis/domain-model/model/node";
import { Permission } from "@iventis/domain-model/model/permission";
import { PermissionOwnerType } from "@iventis/domain-model/model/permissionOwnerType";
import { findNode } from "@iventis/tree-browser/tree-operations";
import { useMemo } from "react";

/**
 * Returns true if User can edit a node, else returns false
 */
export const canEdit = (authorisation: Authorisation) => {
    switch (authorisation) {
        case Authorisation.Admin: {
            return true;
        }
        case Authorisation.Write: {
            return true;
        }
        default: {
            return false;
        }
    }
};

/** Can use edit all items inside the array */
export const canEditAll = <T extends { authorisation: Authorisation }>(items: T[]) => (items?.length > 0 ? items.every((item) => canEdit(item.authorisation)) : false);

/**
 * Returns true if User can read a node, else returns false
 */
export const canRead = (authorisation: Authorisation) => {
    switch (authorisation) {
        case Authorisation.Admin: {
            return true;
        }
        case Authorisation.Write: {
            return true;
        }
        case Authorisation.Read: {
            return true;
        }
        default: {
            return false;
        }
    }
};

/**
 * Returns true if User is admin of node, else returns false
 */
export const isAdmin = (authorisation: Authorisation) => authorisation === Authorisation.Admin;

/**
 * Given a list of permissions for a resource (can include inherited ones), is there a resource lock permission?
 */
export const isLocked = (permissions: Permission[]) => permissions.filter((p) => p.authorisation === Authorisation.Lock).length > 0;

/**
 * Given a list of permissions for a resource, does the resource itself have a locked permission?
 */
export const isResourceLocked = (permissions: Permission[], resourceId: string) =>
    permissions.filter((p) => p.authorisation === Authorisation.Lock && p.resourceId === resourceId).length > 0;

/**
 * Returns permission on the node using the given id and node tree
 */
export const getAuthorisation = (nodes: Node[], id: string) => findNode<Node>(nodes, id)?.authorisation;

/**
 * Map an index to a permission (Helpful for algorithms)
 */
export const permissionMapping = {
    [Authorisation.Admin]: 4,
    [Authorisation.Write]: 3,
    [Authorisation.Read]: 2,
    [Authorisation.Deny]: 1,
    [Authorisation.None]: 0,
};

/**
 * Given an array of node ids, returns the lowest permission of the group.
 * This is necessary in case a user selects multiple entities and wants to perfom a grouped action (multiple deletion)
 */
export const lowestAuthorisation = (nodes: Node[], ids: string[]): Authorisation => {
    if (ids.length === 0) {
        return Authorisation.None;
    }

    let lowestPermission = getAuthorisation(nodes, ids[0]);

    if (ids.length === 1) {
        return lowestPermission;
    }
    ids.slice(1).forEach((id) => {
        const perm = getAuthorisation(nodes, id);
        lowestPermission = compareAuthorisations(perm, lowestPermission);
    });

    return lowestPermission;
};

const compareAuthorisations = (perm1: Authorisation, perm2: Authorisation) => {
    if (permissionMapping[perm1] < permissionMapping[perm2]) {
        return perm1;
    }
    return perm2;
};

export const compareAuthorisationsDesc = (auth1: Authorisation, auth2: Authorisation): number =>
    permissionMapping[auth1] < permissionMapping[auth2] ? 1 : permissionMapping[auth1] > permissionMapping[auth2] ? -1 : 0;

export const getLowestPermission = (perm1: Permission, perm2: Permission) => {
    const lowestAuthorisation = compareAuthorisations(perm1.authorisation, perm2.authorisation);
    if (perm1.authorisation === lowestAuthorisation) {
        return perm1;
    }
    if (perm2.authorisation === lowestAuthorisation) {
        return perm2;
    }
    throw new Error("compareAuthorisations returned a permission that did not exist in the input parameters. This is impossible.");
};

/**
 * Given an array of permissions. Return the permissions which are most dominant for each owner.
 * @param permissions List of permissions
 * @returns Permissions array with most dominant owner permissions
 */
export const getDominantOwnersPermissions = (permissions: Permission[]): Permission[] => {
    const ownersDominantPermissions: Permission[] = [];
    // Get distinct owner ids for the permissions, hence the use of Set.
    const permissionOwners = [...new Set(permissions.map((p) => p.ownerId))];

    // For each permission owner, find their most dominate permission
    permissionOwners.forEach((ownerId) => {
        // Gather all permissions for the given owner.
        const ownerInheritedPermissions = permissions.filter((p) => p.ownerId === ownerId);
        // Evaulte the most dominant inherited permission for the owner.
        const ownersMostDominantPermission = ownerInheritedPermissions.sort((a, b) => compareAuthorisationsDesc(a.authorisation, b.authorisation))[0];
        // Push most dominant permission for the owner to the squashed permissions
        ownersDominantPermissions.push(ownersMostDominantPermission);
    });
    return ownersDominantPermissions;
};

/**
 * Return permissions where its resource id matches the current resource id passed in
 * OR
 * The most dominant permission for each owner where the resource id does not match the current resource id.
 * @param permissions List of permissions
 * @param Current resource id that was used to get the permissions
 * @returns Permissions array
 */
export const permissionSquasher = (permissions: Permission[], currentResourceId: string) => {
    // We want to keep any current resource permissions, or permissions that belong to a resource
    const currentResourcePermissions: Permission[] = permissions.filter((p) => p.resourceId === currentResourceId || p.ownerType === PermissionOwnerType.Resource);
    const dominantOwnersPermissions: Permission[] = getDominantOwnersPermissions(
        permissions.filter((p) => p.resourceId !== currentResourceId && p.ownerType !== PermissionOwnerType.Resource)
    );
    return [...currentResourcePermissions, ...dominantOwnersPermissions];
};

export const useReadWritePermissionCheck = (authorisation: Authorisation): [boolean, boolean] => useMemo(() => [canRead(authorisation), canEdit(authorisation)], [authorisation]);
